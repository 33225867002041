import React, { useRef, useEffect, useContext } from "react";
import classes from "./Login.module.css";
import RefInfo from "../../components/Information/RefInfo/RefInfo";
import CGUInfo from "../../components/Information/CGU/CGU";
// UI Components
import Modal from "../../components/UI/Modal/Modal";
import SubmitButton from "../../components/UI/Buttons/SubmitButton/SubmitButton";
// MUI
import Button from "@material-ui/core/Button";
// Routing
import { withRouter, Redirect } from "react-router";
// Contexts
import { LoginContext } from "../../contexts/LoginContext";
import { AuthContext } from "../../contexts/AuthContext";
// Utils
import { anonLogin, checkValidity, addNewLoc } from "../../utils/LoginUtils";

const Login = ({ history }) => {
  const { loginData, dispatch } = useContext(LoginContext);
  const {
    refLoc,
    CGU,
    CGUShow,
    refShow,
    isNewUser,
    loading,
    formIsValid,
  } = loginData;

  useEffect(() => {
    dispatch({
      type: "SET_FORMISVALID",
      formIsValid: refLoc.valid && CGU.valid,
    });
  }, [refLoc.value, CGU.value]);

  // Inputs Change handler with validation
  const inputChangeHandler = (e, element) => {
    let updatedElement = {};
    switch (element) {
      case CGU:
        updatedElement = {
          ...element,
          value: e.target.checked,
          valid: e.target.checked,
        };
        dispatch({ type: element.actionType, element: updatedElement });

        break;
      default:
        updatedElement = {
          ...element,
          value: e.target.value,
          valid: checkValidity(e.target.value, element.validation),
        };
        dispatch({ type: element.actionType, element: updatedElement });
        break;
    }
  };

  // Handles login click & Submits phone number & Opens SMS modal
  // TODO: Add validation
  const loginClickHandler = (e) => {
    e.preventDefault();
    dispatch({ type: "SET_LOADING", loading: true });
    anonLogin(dispatch);
  };

  //Redirect after authentication
  const { currentUser } = useContext(AuthContext);

  // TODO: add time on login if user not new
  if (currentUser) {
    isNewUser ? addNewLoc(refLoc.value, currentUser.uid) : console.log("-");
    return isNewUser ? (
      <Redirect to="/profile-setup" />
    ) : (
      <Redirect to="/travaux" />
    );
  }

  return (
    <div className={classes.Wrapper}>
      <div className={classes.LoginContainer}>
        <div className={classes.Login}>
          <Modal
            show={refShow}
            clicked={() => dispatch({ type: "SET_REFSHOW" })}
          >
            <RefInfo />
          </Modal>

          <Modal
            show={CGUShow}
            clicked={() => dispatch({ type: "SET_CGUSHOW" })}
          >
            <CGUInfo />
          </Modal>

          <div className={classes.ImgWrapper}>
            <img
              src={require("../../assets/images/undraw_buildings.svg")}
              alt="logo"
            />
          </div>
          <h1 className={classes.LoginTitle}>S'identifier pour commencer</h1>

          <form onSubmit={loginClickHandler}>
            <div className={classes.InputGroup}>
              <i
                className="fas fa-info-circle"
                onClick={() => dispatch({ type: "SET_REFSHOW" })}
              ></i>
              <input
                className={classes.Input}
                type="text"
                required
                value={refLoc.value}
                onChange={(e) => inputChangeHandler(e, refLoc)}
              />
              <span className={classes.Highlight}></span>
              <span className={classes.Bar}></span>
              <label className={classes.InputLabel}>Référence locataire</label>
              {!refLoc.valid && refLoc.value !== "" ? (
                <p className={classes.InputError}>
                  Référence invalide (ex: <strong>017123</strong>)
                </p>
              ) : null}
            </div>

            <div className={classes.CGUGroup}>
              <input
                id="CGU"
                type="checkbox"
                checked={CGU.value}
                onChange={(e) => inputChangeHandler(e, CGU)}
              />
              <label htmlFor="CGU">
                En cochant cette case, j'accepte les{" "}
                <span
                  className={classes.CGULink}
                  onClick={() => dispatch({ type: "SET_CGUSHOW" })}
                >
                  conditions générales d'utilisation*
                </span>
                .
              </label>
            </div>

            <div className={classes.SubmitButtonContainer}>
              <SubmitButton
                loading={loading}
                clicked={loginClickHandler}
                isValid={formIsValid}
              >
                S'IDENTIFIER
              </SubmitButton>
            </div>
            <div className={classes.AnonLogin}>
              <Button
                size="small"
                style={{ color: "#828489" }}
                variant="outlined"
                onClick={() => anonLogin(dispatch)}
              >
                Je n'ai pas de référence locataire
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
